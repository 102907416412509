<template>
  <en-drawer title="查询保养数据" :model-value="modelValue" @close="$emit('update:model-value', false)" :size="1200">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" label-position="left" label-width="80">
      <en-form-item label="VIN" prop="vinCode">
        <en-input v-model="form.data.vinCode"></en-input>
      </en-form-item>
      <en-form-item label="保养里程" prop="mileage">
        <en-input v-model="form.data.mileage"></en-input>
      </en-form-item>
      <en-form-item>
        <button-ajax :disabled="table.loading" :method="form.confirm.click">查询保养项目</button-ajax>
      </en-form-item>
    </en-form>

    <en-table :data="table.data" :height="500" :loading="table.loading" @selection-change="table.selection.change">
      <en-table-column type="selection" width="55"></en-table-column>
      <en-table-column label="保养项目" prop="item"></en-table-column>
      <en-table-column label="保养方式" prop="keepModel">
        <template #default="{ row }: { row: EnocloudCommonDefinitions['MingjueMaintainDto'] }">{{ row.keepModel || '-' }} </template>
      </en-table-column>
      <en-table-column label="用量" prop="qty">
        <template #default="{ row }: { row: EnocloudCommonDefinitions['MingjueMaintainDto'] }">
          <en-input-number :modelValue="Number(row.qty)" :min="0" @change="table.qty.change(row, $event)"></en-input-number>
        </template>
      </en-table-column>
      <en-table-column label="详细参数" prop="oilAttrs">
        <template #default="{ row }: { row: EnocloudCommonDefinitions['MingjueMaintainDto'] }"
          >{{ row.oilAttr?.length ? row.oilAttr.map((o) => `${Object.values(o)[0]}:${Object.values(o)[1]}`).join(',') : '-' }}
        </template>
      </en-table-column>
      <en-table-column label="操作">
        <template #default="{ $index }: { $index: number }">
          <en-button type="primary" text @click="table.operation.delete.click($index)">删除</en-button>
        </template>
      </en-table-column>
    </en-table>

    <template #footer>
      <en-button :disabled="table.loading" @click="$emit('update:model-value', false)">取消</en-button>
      <en-button type="primary" :disabled="table.loading" @click="$emit('confirm', table.selection.data), $emit('update:model-value', false)">
        确定
      </en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    vin: String,
    mileage: String
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value: EnocloudCommonDefinitions['MingjueMaintainDto'][]) => typeof value === 'object'
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible) {
          this.form.data.vinCode = this.vin ?? ''
          this.form.data.mileage = this.mileage ?? ''
          this.table.get()
        } else {
          this.form.data.vinCode = ''
          this.form.data.mileage = ''
          this.table.data = []
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data: {
          vinCode: '',
          mileage: ''
        },
        rules: {
          vinCode: [{ required: true, message: '请输入vin码' }],
          mileage: [{ required: true, message: '请输入保养里程' }]
        },
        children: {
          confirm: {
            async click() {
              await this.refs.form.validate()
              return this.table.get()
            }
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/maintain',
            data: 'array',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        children: {
          operation: {
            delete: {
              click(index: number) {
                this.table.data.splice(index, 1)
              }
            }
          },
          selection: {
            data: [] as EnocloudCommonDefinitions['MingjueMaintainDto'][],
            change(rows: EnocloudCommonDefinitions['MingjueMaintainDto'][]) {
              this.table.selection.data = rows
            }
          },
          qty: {
            change(row: EnocloudCommonDefinitions['MingjueMaintainDto'], value: number) {
              row.qty = value.toString()
            }
          }
        }
      }
    }
  }
})
</script>
